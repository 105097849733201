
  import Testimonials from "@/components/Testimonials.vue";
  import { Vue, Component } from "vue-property-decorator";

  @Component({
    components: {
      Testimonials,
    },
  })
  export default class MDTP extends Vue {
    disabled = false;
    selectedTestimonial = null;
    tab = null;
    panel = null;
    faqs = [
      {
        id: 1,
        question: "What are the basic components of MDTP?",
        answer:
          "MDTP is based on exercise and motor learning principles. These are reviewed and explained in this course. MDTP components include a structured and validated clinical and instrumental assessment, application of food hierarchy with clinical decision strategies, and specific recommendations for monitoring patient performance and advancing safe oral intake.",
        expanded: false,
      },
      {
        id: 2,
        question: "Are modalities used with MDTP?",
        answer:
          "Though we have used MDTP with NMES and sEMG biofeedback, MDTP is a stand alone clinical approach to dysphagia therapy in adults. While some patients might benefit from application of adjunctive modalities in dysphagia therapy, this course will not address the inclusion of adjunctive modalities.",
        expanded: false,
      },
      {
        id: 3,
        question: "Who benefits from MDTP?",
        answer:
          "Our initial clinical research focused on adult patients who have chronic dysphagia (a minimum of 6 months) who had 'failed' at prior dysphagia interventions (no improvement in safe oral intake). Our latest randomized controlled trial was completed with sub-acute stroke patients. We have successfully used MDTP with a range of dysphagia severities and etiologies, including with patients who survived stroke, head/neck cancer treatment, Parkinson’s disease, and traumatic brain injury. While not every patient has improved following MDTP intervention, our success rate is over 90% for increased safe oral intake and nearly 70% for removal of feeding tubes in treated patients.",
        expanded: false,
      },
      {
        id: 4,
        question: "Will I be able to use the MDTP approach after this one-day course?",
        answer:
          "Yes, the one-day course will provide you will all the basics that an experienced clinician needs to conduct MDTP therapy. If you have questions once you start using MDTP, the course instructors are available via email to respond to your questions.",
        expanded: false,
      },
      {
        id: 5,
        question: "Who is McNeill?",
        answer:
          "MDTP has been tested in clinical research since 2005. Hannibal McNeill was one of the first patients who volunteered to participate in this line of clinical research. Hannibal had survived both a brainstem stroke and treatment for head and neck cancer. He entered MDTP therapy on a thick liquid diet and left three weeks later eating his favorite food...hamburger and French fries. Nearly two years after completion of therapy Hannibal died from a brain hemorrhage. With the permission of his family, we honored his memory by naming this therapy approach after him.",
        expanded: false,
      },
    ];

    expandFAQ(clickedFAQ) {
      this.faqs.forEach((faq) => {
        faq.expanded = faq.id === clickedFAQ.id && !clickedFAQ.expanded;
      });
    }
  }
